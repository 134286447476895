








































  import { Component, Watch, mixins } from 'nuxt-property-decorator'
  import { namespace, Action, State } from 'vuex-class'
  import LazyHydrate from 'vue-lazy-hydration'
  import SeoMethodsMixin, { DIVIDER } from '../mixins/SeoMethodsMixin'
  import OneOfflineNotification from '~/components/molecules/notifications/OneOfflineNotification.vue'
  import { StickyStack } from '~/plugins/stickystack'
  import { EnsureClientSide } from '~/utils/clientSideDecorator'
  const layout = namespace('layout')
  const account = namespace('account')
  const cms = namespace('cms')
  const idle = namespace('idle')

  interface MetaInfo {
    title: string
    titleTemplate: string
    meta: Array<any>
    link: Array<any>
    script: Array<any>
    noscript: Array<any>
  }

  enum HeadTagsTypes {
    link = 'link',
    script = 'script',
    meta = 'meta',
    noscript = 'noscript',
  }

  @Component({
    components: {
      OneAvailableNotificationUpdate: () =>
        import('~/components/molecules/notifications/OneAvailableNotificationUpdate.vue'),
      OneHeaderGridComponent: () =>
        import('~/components/organisms/header/OneHeaderGridComponent.vue'),
      OneFooter: () => import('~/components/organisms/footer/OneFooter.vue'),
      OneOfflineNotification,
      OneButtonToggle: () => import('~/components/molecules/buttons/OneButtonToggle.vue'),
      MobileMenuWrapper: () => import('~/components/molecules/menus/MobileMenuWrapper.vue'),
      MobileAccountMenuWrapper: () =>
        import('~/components/molecules/menus/MobileAccountMenuWrapper.vue'),
      OneBreadcrumbs: () => import('~/components/molecules/breadcrumbs/OneBreadcrumbs.vue'),
      OneCookieBanner: () => import('~/components/molecules/banners/OneCookieBanner.vue'),
      OneNoticeBanner: () => import('~/components/molecules/banners/OneNoticeBanner.vue'),
      OneInvoiceBanner: () => import('~/components/molecules/banners/OneInvoiceBanner.vue'),
      OneFullScreenPluginModal: () => import('~/components/molecules/modals/OneFullScreenPluginModal.vue'),
      OneCmsYoutubeComponent: () =>
        import('~/components/organisms/grid/shared/OneCmsYoutubeComponent.vue'),
      LazyHydrate,
    },
  })
  export default class vmMainLayout extends mixins<SeoMethodsMixin>(SeoMethodsMixin) {
    @State tenantKey!: string | null
    @layout.State menuAccountShow: any
    @layout.State menuMobileShow: any
    @layout.State menuFiltersShow: any
    @layout.Mutation('TOGGLE_BACKDROP') backdropRun: any
    @layout.Mutation('TOGGLE_ACCOUNT_MENU') toggleMenuAccount: any
    @layout.Mutation('TOGGLE_MOBILE_MENU') toggleMobileMenu: any
    @layout.Mutation('SET_PRICE_GROSS') setPriceGross: any
    @layout.State breadcrumbs!: Array<any>
    @layout.State showBreadcrumbs!: boolean
    @layout.State backdrop: any
    @layout.State(state => state.priceGross) isPriceGross!: boolean
    @Action fetchHeavyData: any
    @cms.Getter configuration: any
    @cms.Getter logo: any
    @account.Getter getClient: any
    @idle.State isIdle: any

    name: string = 'MainLayout'
    stack!: StickyStack
    updateCallback: Function | null = null

    head() {
      const defaultHeader: MetaInfo = {
        title: this.structuredSeoTitle(DIVIDER.DOT, [this.defaultSeoData.title]),
        // @ts-ignore
        titleTemplate: (titleChunk: string) => this.defaultSeoTitleTemplate(titleChunk),
        meta: [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width, minimum-scale=1, maximum-scale=1' },
          {
            hid: 'description',
            name: 'description',
            content: this.structuredSeoTitle(DIVIDER.DASH, [this.defaultSeoData.description]),
          },
          { hid: 'author', name: 'author', content: 'Unity Group' },
          { hid: 'robots', name: 'robots', content: this.defaultSeoRobots },
        ],
        link: [
          {
            rel: 'icon',
            type: 'image/x-icon',
            href: (this.logo && this.logo.favicon) || 'favicon.png',
          },
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: this.breadcrumbs.map((item, index) => {
                return {
                  '@type': 'ListItem',
                  position: index + 1,
                  item: item.name,
                }
              }),
            },
          },
        ],
        noscript: [],
      }
      this.setTenantIcons(defaultHeader)
      this.setPwaAttributes(defaultHeader)
      this.setTenantCss(defaultHeader)
      this.setScripts(defaultHeader)
      this.setHeadTags(defaultHeader)
      return defaultHeader
    }

    setHeadTags(header: MetaInfo) {
      try {
        const headTags = this.configuration.globalTags?.tagsHeadHtml
        if (headTags == null || JSON.parse(headTags)?.tags == null) {
          return
        }
        const tagsObject = JSON.parse(headTags)
        if (tagsObject.tags == null) {
          return
        }
        const tenantScriptsOff = this.$route.query?.tenantScriptsOff === 'true'

        tagsObject.tags.forEach(
          ({ type, attributes }: { type: HeadTagsTypes; attributes: any }) => {
            if (type === 'script' && tenantScriptsOff) {
              return
            }
            header[type] = header[type] ? [...header[type], attributes] : [attributes]
          },
        )
      } catch (error) {
        this.$logger.warn('[LAYOUT - setHeadTags]', error)
      }
    }

    setTenantIcons(header: MetaInfo) {
      // TODO: Dodac brakujące typy
      try {
        if (this.$tenantConfig.configuration?.theme.themeIcons) {
          header.link.push({
            rel: 'stylesheet',
            href: this.$tenantConfig.configuration.theme.themeIcons.css,
            pbody: true,
          })
        }
      } catch (error) {
        this.$logger.warn('[LAYOUT - setTenantIcons]', error)
      }
    }

    setTenantCss(header: MetaInfo) {
      try {
        // @ts-ignore
        if (this.$tenantConfig.configuration?.globalCssUrl) {
          header.link.push({
            rel: 'stylesheet',
            // @ts-ignore
            href: this.$tenantConfig.configuration.globalCssUrl,
            pbody: true,
          })
        }
      } catch (error) {
        this.$logger.warn('[LAYOUT - setTenantCss]', error)
      }
    }

    setScripts(header: MetaInfo) {
      try {
        // @ts-ignore
        const globalScripts = this.$tenantConfig.configuration?.globalScripts
        const tenantScriptsOff = this.$route.query?.tenantScriptsOff === 'true'

        if (!globalScripts || tenantScriptsOff) {
          return
        }

        const {
          scriptsHeadUrl: headerScript,
          scriptsBodyUrl: bodyScript,
          async,
          defer,
        } = globalScripts
        header.script.push({
          rel: 'script',
          body: false,
          src: headerScript,
          async,
          defer,
        })
        header.script.push({
          rel: 'script',
          body: true,
          src: bodyScript,
          async,
          defer,
        })
      } catch (error) {
        this.$logger.warn('[LAYOUT - setScripts]', error)
      }
    }

    setPwaAttributes(header: MetaInfo) {
      try {
        if (this.$pwa.isEnabled) {
          header.link = [
            ...header.link,
            { rel: 'manifest', href: '/api/manifest.json' },
            { rel: 'apple-touch-icon', href: this.$pwa.appleIconUrl, sizes: '512x512' },
          ]
          header.meta.push({
            name: 'theme-color',
            content: this.$lib.primary,
          })
        }
      } catch (error) {
        this.$logger.warn('[LAYOUT - setPwaAttributes]', error)
      }
    }

    defaultSeoTitleTemplate(titleChunk: string): string | null {
      return this.configuration?.seo?.title || this.structuredSeoTitle(
        DIVIDER.DOT, [
          titleChunk,
          this.defaultSeoData.title,
        ],
      )
    }

    onUpdateAnswer(accepted: boolean) {
      if (this.updateCallback) {
        this.updateCallback(accepted)
        this.updateCallback = null
      }
    }

    showUpdatePrompt(cb: Function) {
      this.updateCallback = cb
      this.$notify({
        group: 'pwaUpdate',
        duration: -1,
      })
    }

    async created() {
      if (process.client) {
        this.$pwa.$on('pwa:update:prompt', this.showUpdatePrompt)
        this.initializeStickyStack()
        await this.fetchHeavyData()
      }
    }

    @Watch('isIdle')
    onIdle() {
      if (!this.isIdle && this.$auth.isAuthenticated) {
        this.$api.webhooks.app.publishClientLogged({
          externalId: this.getClient.externalId,
        })
      }
    }

    initializeStickyStack() {
      this.$nextTick(() => {
        this.stack = new StickyStack()
      })
    }

    destroyed() {
      this.$pwa.$off('pwa:update:prompt', this.showUpdatePrompt)
    }

    onCategoryClick(categoryId: string | null) {
      if (categoryId) {
        const categoryPath: string | null = this.$routes.getCategoryPath(categoryId)
        if (categoryPath) {
          this.$router.push(categoryPath, () => {
            this.toggleMobileMenu(false)
          })
        }
      }
    }

    menuAccountClose() {
      if (this.menuAccountShow) {
        this.toggleMenuAccount(false)
      }
    }

    closeMobileMenuAfterSelected() {
      this.scrollTop(0)
      this.menuAccountClose()
    }

    get logoData() {
      return this.configuration?.logo?.img
    }

    get backdropCategory() {
      return {
        booleanValue: this.backdrop,
        uid: 'category',
      }
    }

    @Watch('menuFiltersShow', { immediate: true })
    overflowFiltersLayout() {
      if (process.client) {
        const layoutOverflow = this.menuFiltersShow ? 'hidden' : 'unset'
        // @ts-ignore
        document.getElementById('__layout').style.overflowX = layoutOverflow
      }
    }

    @EnsureClientSide
    @Watch('$route.path', { immediate: true })
    onRouteChange() {
      document.body.className =
        this.$route.path.substring(1).replace(/\//g, '-').replace(/-\s*$/, '') || 'index'
    }

    @Watch('menuMobileShow', { immediate: true })
    overflowMobileLayout() {
      if (process.client) {
        const layoutOverflow = this.menuMobileShow ? 'hidden' : 'unset'
        // @ts-ignore
        document.getElementById('__layout').style.overflowX = layoutOverflow
      }
    }
  }
